var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "work-place-setup" },
      [
        _c("h4", [_vm._v("参与统计的车辆选择")]),
        _c("span", { staticClass: "remark" }, [
          _vm._v("被选中的车辆，其数据将被统计、展示在dashboard中"),
        ]),
        _c(
          "div",
          { staticStyle: { "margin-top": "10px" } },
          [
            _c("object-tree", {
              ref: "vehicleTree",
              staticStyle: { height: "45vh" },
              attrs: {
                "tree-data": _vm.vehicleTreeData,
                "place-holder": "输入车牌或车辆自编号进行搜索",
                "object-title": "自动驾驶车辆",
                "cache-key": _vm.cacheKey,
                "object-icon": "vehicleAutoIcon",
                defaultExpandAll: true,
              },
              on: { check: _vm.handleCheckVehicle },
            }),
          ],
          1
        ),
        _c("a-divider", { staticStyle: { height: "1px", margin: "10px 0" } }),
        _c("h4", { staticStyle: { "margin-top": "0px" } }, [
          _vm._v("统计区间选择"),
        ]),
        _vm._m(0),
        _c("div", { staticClass: "date-item" }, [
          _c(
            "div",
            { staticClass: "date-col" },
            [
              _c("span", { staticClass: "label" }, [_vm._v("开始日期：")]),
              _c("a-date-picker", {
                staticStyle: { width: "100%" },
                attrs: {
                  "value-format": "YYYY-MM-DD",
                  format: "YYYY-MM-DD",
                  "allow-clear": "",
                },
                model: {
                  value: _vm.beginDate,
                  callback: function ($$v) {
                    _vm.beginDate = $$v
                  },
                  expression: "beginDate",
                },
              }),
            ],
            1
          ),
          _vm.mode === 2
            ? _c(
                "div",
                { staticClass: "date-col" },
                [
                  _c("span", { staticClass: "label" }, [_vm._v("结束日期：")]),
                  _c("a-date-picker", {
                    staticStyle: { width: "100%" },
                    attrs: {
                      "value-format": "YYYY-MM-DD",
                      format: "YYYY-MM-DD",
                      "allow-clear": "",
                    },
                    model: {
                      value: _vm.endDate,
                      callback: function ($$v) {
                        _vm.endDate = $$v
                      },
                      expression: "endDate",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ]),
        _c(
          "div",
          {
            staticClass: "bottom-control",
            staticStyle: { "margin-top": "30px" },
          },
          [
            _c(
              "a-space",
              [
                _c(
                  "a-button",
                  { attrs: { type: "primary" }, on: { click: _vm.submitForm } },
                  [_vm._v(" 确定 ")]
                ),
                _c(
                  "a-button",
                  {
                    attrs: { type: "dashed" },
                    on: { click: _vm.handleCancel },
                  },
                  [_vm._v(" 取消 ")]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "remark" }, [
      _vm._v("日期区间内的数据将被统计展示。 由于有多项数据是按周统计的，"),
      _c("br"),
      _vm._v("若所选日期落在某个周内（例如周三），系统会自动调整，补全该周。"),
      _c("br"),
      _vm._v("补全规则：从周一开始，周日结束。"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }