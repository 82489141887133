<template>
  <div>
    <div class="work-place-setup">
      <h4>参与统计的车辆选择</h4>
      <span class="remark">被选中的车辆，其数据将被统计、展示在dashboard中</span>
      <div style="margin-top: 10px">
        <object-tree
          style="height: 45vh"
          ref="vehicleTree"
          :tree-data="vehicleTreeData"
          place-holder="输入车牌或车辆自编号进行搜索"
          object-title="自动驾驶车辆"
          :cache-key="cacheKey"
          object-icon="vehicleAutoIcon"
          @check="handleCheckVehicle"
          :defaultExpandAll="true"
        />
      </div>
      <a-divider style="height: 1px; margin: 10px 0" />
      <h4 style="margin-top: 0px">统计区间选择</h4>
      <span class="remark">日期区间内的数据将被统计展示。 由于有多项数据是按周统计的，<br />若所选日期落在某个周内（例如周三），系统会自动调整，补全该周。<br />补全规则：从周一开始，周日结束。</span>
      <div class="date-item">
        <div class="date-col">
          <span class="label">开始日期：</span>
          <a-date-picker style="width: 100%" v-model="beginDate" value-format="YYYY-MM-DD" format="YYYY-MM-DD" allow-clear />
        </div>
        <div class="date-col" v-if="mode === 2">
          <span class="label">结束日期：</span>
          <a-date-picker style="width: 100%" v-model="endDate" value-format="YYYY-MM-DD" format="YYYY-MM-DD" allow-clear />
        </div>
      </div>
      <div class="bottom-control" style="margin-top: 30px">
        <a-space>
          <a-button type="primary" @click="submitForm"> 确定 </a-button>
          <a-button type="dashed" @click="handleCancel"> 取消 </a-button>
        </a-space>
      </div>
    </div>
  </div>
</template>
<script>
import ObjectTree from '@/views/monitor/monitorComponents/objectTree'

export default {
  props: {
    cacheKey: {
      type: String,
      default: () => {}
    },
    fromDate: {
      type: String,
      default: () => {}
    },
    toDate: {
      type: String,
      default: () => {}
    },
    mode: {
      type: Number,
      default: () => {}
    },
    treeData: {
      type: Array,
      default: () => {}
    },
    vehicleIds: {
      type: Array,
      default: () => {}
    }
  },
  components: {
    ObjectTree
  },
  data() {
    return {
      beginDate: this.fromDate,
      endDate: this.toDate,
      currentCheckedList: [],
      vehicleTreeData: []
    }
  },
  watch: {
    fromDate(newVal, oldVal) {
      this.beginDate = newVal
    },
    toDate(newVal, oldVal) {
      this.endDate = newVal
    },
    vehicleIds(newVal, oldVal) {
      this.currentCheckedList = newVal
    },
    treeData(newVal, oldVal) {
      this.vehicleTreeData = newVal
    }
  },
  mounted() {
    // this.setDefaultCheckData()
    this.vehicleTreeData = this.treeData
    this.currentCheckedList = this.vehicleIds
  },
  methods: {
    // 打勾选择设备，当前打勾的设备列表，当前选中的设备Id
    handleCheckVehicle(checkedList, selectedId, isSameList) {
      console.log('handleCheckVehicle', checkedList)
      this.currentCheckedList = checkedList
    },

    submitForm: function () {
      // 此处只是确认，不提交后台保存，只用于本地查询
      console.log('dateRange', this.dateRange)
      if (!this.beginDate) {
        this.$warningEx('请选择统计开始日期！')
        return
      }
      this.$emit('ok', this.beginDate, this.endDate, this.currentCheckedList)
    },
    handleCancel() {
      this.$emit('cancel')
    }
  }
}
</script>
<style lang="less" scoped>
.work-place-setup {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .remark {
    margin-top: -5px;
    font-size: 11px;
    color: #b5b5b5;
  }
  .date-item {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    .date-col {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .label {
        flex: 0 0 75px;
      }
    }
  }
}
</style>
